import Splide from '@splidejs/splide';


document.addEventListener('DOMContentLoaded', () => {
    const splide = document.querySelectorAll('.splide');
    if (splide.length > 0) {
        splide.forEach((s) => {
            new Splide(s).mount();
        });
    }
});




if ('scrollRestoration' in history) {

    history.scrollRestoration = 'manual';
}


window.addEventListener('DOMContentLoaded', function () {
  const grid = document.querySelector('.loop');
  const loaderWrapper = document.querySelector('[data-loader]');
  const catSelector = document.querySelectorAll('.term-nav input');
  const loader = document.createElement('a');
  loader.setAttribute('class', 'btn--rev loader');
  loader.setAttribute('data-page', 1);
  loader.innerText = 'Load More'
    if (parseInt(loaderWrapper.dataset['loader']) === 0) {
        loader.classList.add('hidden');
    }
  this.document.querySelector('.loader-wrapper').append(loader);

  
  class AjaxLoader {
      constructor(options) {
          this.total = null;
          this.page = 1;
          this.type = grid.dataset['type'];
          this.taxonomy = 'all';
          this.options = options
        }

        rewriteUrl() {
            if (this.taxonomy !== 'all') {
                window.history.replaceState({}, '', `/blog?category_name=${this.taxonomy}`);  
            } else {
                window.history.replaceState({}, '', '/blog/');  
            }
        }

        call(elem) {
            this.element = elem;
            if (this.element.hasAttribute('data-page')) {
                this.page++
                if (this.total && this.total <= this.page) {
                    this.element.classList.add('hidden');    
                }
            } else {
                this.taxonomy = this.element.dataset['post'] || 'all';
                this.page = 1;
            }
            this.loadPosts();
            console.log(this.taxonomy);
        }
        
        
        async loadPosts() {
            
            grid.classList.add('loading');
            await fetch(`${this.options.ajaxUrl}?${new URLSearchParams({
                action: 'ajax_call',
                category: this.taxonomy,
                type: this.type,
                page: this.page,
                })}`, {
                method: 'GET',
            }).then((response) => {
                return response.json();
           
            }).then((data) => {
                this.handledResponse(data);
            }).catch(function (err) {
                console.warn('Something went wrong.', err);
            }); 
        }
        
        scrollTo() {
            window.scroll({
                top: parseInt(loaderWrapper.offsetTop, 10) - 300,
                behavior: 'smooth'
            });
        }
        
        
        setUrl(pageId) {
            
            if (parseInt(pageId, 10) > 1) {
                window.history.replaceState({}, '', `/blog/page/${pageId}/`);  
            } else {
                window.history.replaceState({}, '', '/blog/');  
            }
        }
        
        handledResponse(response) {
            this.rewriteUrl();
            this.total = response.data.total;
            if (this.total && this.total > this.page) {
                loader.classList.remove('hidden');
            } else {
                loader.classList.add('hidden');

            }
            if (this.element.hasAttribute('data-post')) {
                grid.innerHTML = response.data.posts;
            } else {
                grid.insertAdjacentHTML( 'beforeend', response.data.posts );
            }
           

            setTimeout(() => {
                document.querySelectorAll('.post').forEach((item) => {
                    item.classList.remove('loading');
                })
            }, 200)
        }
    }
    
    const ajax = new AjaxLoader({
        ajaxUrl: window.urls.ajax
    });
    if (catSelector.length > 0) {
        catSelector.forEach((radio, i) => {
            radio.addEventListener('click', (e) => {
                e.stopPropagation();
                ajax.call(e.target);
            })
        });

    }
    if (loader) {
        
        loader.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            ajax.call(e.target);
        });
    }

});
document.addEventListener('DOMContentLoaded', () => {
    
    const toggler = document.querySelectorAll('[data-toggle]');
    const submenu = document.querySelectorAll('.menu-item-has-children');
    
    if (toggler.length > 0) {
        toggler.forEach((item, i) => {
            item.addEventListener('click', () => {
                document.body.classList.toggle(item.dataset.toggle);
            });
        })
    }

   
});


function setWebhook() {
   
}


document.addEventListener('DOMContentLoaded', () => {

  
    
    const btn = document.querySelector('[data-commerce]');
    if (btn) {
        btn.addEventListener('click', () => {
            setWebhook();
        });
    }
});
